.CategoryBanner {
    margin-bottom: 2px;
    display: grid;
    grid-template-columns: 3.5% 10% 1fr;
    grid-gap: 2px;
    width: 100%;

    &.fullWidth {
        display: flex;
    }

    &.openUrl {
        cursor: pointer;
    }

    .CategoryBannerImage {
        width: 100%;

        &.mobile {
            display: none;
        }

        &.desktop {
            display: flex;
        }
    }

    .CategoryBannerText {
        width: 100%;
        padding: 15px 15px;
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        border-radius: 2pt;

        h3 {
            margin: 0 0 8px;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
        }

        p {
            margin: 0;
            font-size: 15px;
            font-weight: normal;
            text-align: left;
        }
    }
}

@media screen and (max-width: 600px) {
    .CategoryBanner {
        .CategoryBannerImage {
            &.mobile {
                display: flex;
            }

            &.desktop {
                display: none;
            }
        }
    }
}
