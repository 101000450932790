input {
    font-family: "Open Sans", sans-serif !important;
}

ion-content {
    --background: #e6e6e6;

    &.fullScreen {
        min-height: 100vh;

        > ion-grid {
            margin-top: 100px !important;
        }
    }
}

#App {
    position: relative;
}

.Container {
    max-width: 1550px;
    margin: 2px auto;
    text-align: center;

    &.smallContainer {
        max-width: 1150px;
        margin: 0 auto;
    }

    footer {
        margin-top: 24px;
        border-top: 1px solid #cccaca;
        padding: 24px;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        color: #333333;
        text-align: left;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-barion {
            width: 250px;
            display: block;
            margin-bottom: 10px;
        }

        .right {
            a {
                margin-right: 10px;
                font-weight: 500;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

.marginInner {
    margin: 125px auto 24px;
}

ion-card {
    margin: 0;
    padding: 0;
    border-radius: 2pt;

    ion-card-header {
        padding: 10px 25px;
        border-bottom: 1px solid #ebebeb;
    }

    ion-card-title {
        margin-left: 10px;
        font-size: 16px;
        color: var(--ion-color-dark-shade);
        font-weight: 700;
        text-transform: uppercase;
        line-height: 60px;
        margin: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .checkoutBadge {
            font-weight: 600;
            font-size: 22px;
            background: var(--ion-color-primary);
            color: #fff;
            height: 35px;
            width: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
        }

        &.justify {
            justify-content: space-between;
            flex-flow: row;
            width: 100%;

            div {
                display: inline-flex;
                align-items: center;
                justify-content: center;

                ion-button {
                    --padding-top: 10px;
                    --padding-bottom: 10px;
                    height: 30px;
                }
            }
        }
    }

    ion-card-content {
        padding: 25px 25px !important;
    }

    &.buttonCard {
        margin-bottom: 15px;
        box-shadow: none;
        border: 1px solid #e8e8e8;
        background: #fafafa;
    }

    &.centerCard {
        /*padding: 40px 24px;
        width: 100%;
        max-width: 500px;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;*/
        padding: 40px 24px;
        width: 100%;
        max-width: 500px;
        position: relative;
        margin: 150px auto;

        ion-card-header {
            padding: 0 0 40px 0;
            border-bottom: 0;
        }

        ion-card-title {
            font-family: "Yanone Kaffeesatz", sans-serif;
            font-size: 46px;
            text-align: left;
            font-weight: bold;
            font-style: normal;
            line-height: 60px;
            margin-bottom: 15px;
            color: var(--ion-color-dark-shade);
        }

        ion-card-subtitle {
            font-size: 16px;
            text-align: left;
            font-weight: normal;
            font-style: normal;
            line-height: 26px;
            color: var(--ion-color-medium-shade);
            text-transform: none;
            letter-spacing: normal;
            margin: 0;
        }

        ion-card-content {
            padding: 0 !important;
        }
    }
}

.Category {
    display: grid;
    grid-template-columns: 3.5% 10% 1fr;
    grid-gap: 2px;
    height: auto;
    margin-bottom: 2px;

    .CategoryCode,
    .CategoryName {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        border-radius: 2pt;
        font-weight: 800;
        padding: 10px 15px;
        color: #fff;

        .weeklyButtons {
            display: flex;
            margin-top: 8px;

            .Quantity {
                color: #444;
                height: 27.5px;
                width: 35px;
                border: 0;
                background: #efefef;
                border-radius: 2pt;
                margin: 0 2px;
                text-align: center;
                font-weight: 600;
                padding: 0;
                font-size: 13px;
                line-height: 27.5px;
            }

            button {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .CategoryCode {
        background: var(--ion-color-primary);
    }

    .CategoryName {
        background: var(--ion-color-primary);
    }
}

.menu-content-open {
    pointer-events: all !important;
    cursor: inherit !important;
}

ion-toast {
    position: fixed;
    left: 0;
    top: 0;
}

ion-modal {
    &.modal-fullscreen {
        position: fixed;
        left: 0;
        top: 0;
        border: 0;
        --border-radius: 2pt;

        .modal-close {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 50px;
            height: 50px;
            font-size: 40px;
            background: transparent;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: var(--ion-color-medium);
        }

        .modal-wrapper {
            background: #fff;
            max-width: 1150px;
            max-height: 660px;
            border: 0;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05);

            ion-content {
                --background: transparent;
                border: 0;

                ion-grid {
                    padding: 0;
                    height: 100%;
                    border: 0;

                    ion-row {
                        &.h-100 {
                            height: 100%;
                        }

                        ion-col {
                            &.h-100 {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    &.modal-large {
        --width: 60vw;
        --height: 70vh;
    }
}

.zero-margin {
    margin: 0 !important;
}

.checkbox-input {
    --color: var(--ion-color-medium);
    --background: var(--ion-item-background);
    --padding-start: 5px;
    margin-bottom: 20px;
}

.rounded-input {
    --border-color: var(--ion-color-medium);
    --color: var(--ion-color-medium);
    --border-radius: 2pt;
    --border-width: 1px;
    --highlight-height: 0;
    --background: var(--ion-item-background);
    --min-height: 60px;
    margin-bottom: 20px;

    .sc-ion-label-ios-h {
        -webkit-transform: translate3d(0, 13px, 0);
        transform: translate3d(0, 13px, 0);
    }

    ion-button {
        margin-top: 20px;
        max-height: 20px;
    }

    &.small-input {
        --min-height: 60px;
        margin-bottom: 15px;
        overflow: visible;

        .sc-ion-label-ios-h {
            position: absolute;
            font-size: 15px;
            background: #fff;
            width: auto;
            margin: 0;
            align-self: auto;
            padding: 0;
            -webkit-transform: translate3d(0, 20x, 0);
            transform: translate3d(0, 20px, 0);
            transition: all 0.2s ease;
        }

        ion-button {
            margin-top: 15px;
            max-height: 20px;
        }
    }

    &.item-has-value,
    &.item-has-focus {
        --color: var(--ion-color-secondary);
        --border-color: var(--ion-color-secondary);

        ion-label {
            color: var(--ion-color-secondary);
        }

        &.small-input {
            .sc-ion-label-ios-h {
                font-size: 12px;
                padding: 0 5px;
                -webkit-transform: translate3d(0, -8px, 0);
                transform: translate3d(0, -8px, 0);
                transition: all 0.2s ease;
            }
        }
    }

    &.error {
        --border-color: var(--ion-color-danger);
        --color: var(--ion-color-danger);

        ion-label {
            color: var(--ion-color-danger);
        }
    }

    input {
        background-color: transparent !important;
        color: inherit !important;
    }
}

.errorsList {
    margin-bottom: 15px;
    
    p {
        font-size: 14px;
        margin: 8px 0;
    }
}

.progressbarSteps {
    counter-reset: step;
    padding: 0;
    width: 100%;

    li {
        list-style-type: none;
        width: 25%;
        float: left;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: var(--ion-color-medium);
        font-weight: 600;

        &:before {
            width: 30px;
            height: 30px;
            content: counter(step);
            counter-increment: step;
            line-height: 30px;
            border: 2px solid var(--ion-color-medium);
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            background-color: white;
            font-weight: 800;
            font-size: 14px;
        }

        &:after {
            width: 100%;
            height: 2px;
            content: "";
            position: absolute;
            background-color: var(--ion-color-medium);
            top: 15px;
            left: -50%;
            z-index: -1;
        }

        &:first-child:after {
            content: none;
        }

        &.active {
            color: #fff;

            &:before {
                border-color: var(--ion-color-success);
                background: var(--ion-color-success);
            }

            & + li:after {
                background-color: var(--ion-color-success);
            }
        }
    }
}

.ErrorBox {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    h1 {
        font-size: 80px;
        color: var(--ion-color-medium);
        margin: 0;
    }

    h3 {
        margin: 25px 0 0;
        font-size: 18px;
        line-height: 26px;
        color: var(--ion-color-medium);
        max-width: 500px;
    }

    h2 {
        margin: 0;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        max-width: 80%;
        color: var(--ion-color-primary-shade);
    }
}

.orderDetails {

    ion-card {
        ion-card-content {
            padding: 25px 25px !important;
        }
    }

    hr {
        background: var(--ion-color-medium-shade);
    }

    .leftRight {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        font-size: 14px;
        color: var(--ion-color-medium-shade);
        margin: 5px 0;

        &.bold {
            font-weight: 600;
            color: var(--ion-color-dark);
        }
    }

    .coupon-section {
        ion-item  {
            margin: 0;
        }
    }

    .emptyCart {
        font-weight: 300;
        font-size: 14px;
        color: var(--ion-color-medium-shade);
        margin: 5px 0;
    }
}

.boxRadio {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(80px, 1fr);
    grid-gap: 10px;

    &.twoColumns {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(150px, 1fr);
    }

    label {
        input {
            display: none;
        }
    }

    /*&#locationGroup {
        label {
            justify-content: flex-start;

            .method-content {
                align-items: flex-start;
                flex-flow: column;
                text-align: left;
            }
        }
    }

    label {
        border: 1px solid #ebebeb;
        color: var(--ion-color-medium-shade);
        font-size: 14px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
            border-color: var(--ion-color-primary);
            color: var(--ion-color-primary);
        }

        &.disabled {
            cursor: default;
            opacity: .5;
        }

        input {
            display: none;
        }

        .method-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            text-align: center;

            img {
                max-width: 35px;
                display: flex;
                margin-bottom: 10px;
            }

            .error {
                margin-top: 5px;
                font-size: 12px;
            }
        }
    }*/
}

ion-icon {
    pointer-events: none;
}

.sc-ion-alert-ios-h {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
}

.suggestions {
    h3 {
        margin: 25px 0 0;
        font-size: 17px;
        color: var(--ion-color-dark-shade);
        font-weight: 700;
        text-transform: uppercase;

        &:first-of-type {
            margin: 0;
        }
    }

    p {
        margin: 5px 0 0;
        font-size: 14px;
        color: var(--ion-color-medium);
    }

    .suggestionWrapper {
        margin: 0 0 35px;

        &:last-of-type {
            margin: 0 0 -25px;
        }
    }

    .suggestion-items {
        margin: 15px -25px 0;
        background: #e8e8e8;
        padding: 15px;

        .categoryBadge {
            padding: 3px 8px;
            border-radius: 2pt;
            font-size: 12px;
            margin: 5px 0;
            display: inline-flex;
            font-weight: 700;
        }

        button {
            margin-top: 10px;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: 700;
            width: auto;
        }
    }
}

.foodRatingBox {
    margin-bottom: 30px;
    border-left: 4px solid var(--ion-color-success);
    padding: 20px 25px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .075);
    background: #fff;

    &:last-of-type {
        margin: 0;
    }

    h3 {
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-size: 32px;
        font-weight: 600;
        color: var(--ion-color-success);
        text-transform: uppercase;
        margin: 0 0 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

#input-aszf {
    margin-right: 20px;
}

.avatar-bubble {
    width: 35px;
    height: 35px;
    color: #fff !important;
    font-size: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    line-height: 1;
    font-weight: 700 !important;
    margin: 0 10px 0 0 !important;
    line-height: 0px;
    text-transform: uppercase;
    opacity: 1 !important;
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
 .fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
  @keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
