.location-content {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    text-align: left;
    border: 1px solid #ebebeb;
    border-radius: 2pt;
    overflow: hidden;
    width: 100%;
    color: var(--ion-color-medium-shade);
    font-size: 14px;
    padding: 15px;
    height: 100%;

    &.clicakble {
        cursor: pointer;
    }

    &.active {
        border-color: var(--ion-color-primary);
        color: var(--ion-color-primary);
    }

    &.disabled {
        cursor: default;
        opacity: .5;
    }

    .edit-location {
        position: absolute;
        top: 0;
        right: 30px;
        width: 30px;
        height: 30px;
        padding: 0;
        border-radius: 0;
        z-index: 999;
    }

    .delete-location {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 0;
        border-radius: 0;
        z-index: 999;
    }

    .location-alert {
        background: #d61f25;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        padding: 18px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .8;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }
}
