.MessageCenter {
    .MessageCenter-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 2px;
        text-align: left;
        padding: 10px 15px 10px 0;
        background: #fff;
        margin-bottom: 2px;
        border-left: 4px solid var(--ion-color-primary);
        border-radius: 2pt;
        font-size: 15px;
        font-weight: normal;
        text-align: left;
        display: flex;
        flex-flow: row;
        
        .MessageCenter-icon {
            width: 100px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            ion-icon {
                font-size: 55px;
                color: var(--ion-color-primary);
            }
        }
    }

    p {
        margin: 0;
        line-height: 1.75;
    }
}
