.orderData {
    width: 100%;

    .orderData-div {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;

        &.hasBorder {
            border-radius: 2pt;
            border: 1px solid #e4e4e4;
            padding: 15px;
        }

        .multiLine {
            display: flex;
            flex-flow: column;
            color: var(--ion-color-medium);
            margin-bottom: 5px;

            span {
                &:first-of-type {
                    font-size: 11px;
                    font-weight: 700;
                    text-transform: uppercase;
                    margin: 0 0 4px;
                    opacity: 0.7;
                }

                &:last-of-type {
                    color: var(--ion-color-dark);
                    font-size: 15px;
                    font-weight: 600;

                    ion-chip {
                        pointer-events: none;
                    }
                }
            }

            &.span-col-3 {
                grid-column: span 2 / auto;
            }

            &.details {
                border-top: 1px solid #e4e4e4;
                margin-top: 15px;
                padding-top: 25px;
                grid-column: span 5 / auto;
            }

            table {
                th.weekNum {
                    background: var(--ion-color-primary);
                    color: #fff;
                    font-size: 12px;
                    font-weight: 800;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 5px 10px;
                }
            }
        }
    }

    .orderChangeButtons {
        align-items: center;
        justify-content: flex-start;
        display: flex;

        .Quantity {
            display: inline-block;
            height: 26.5px;
            width: 35px;
            border: 0;
            background: #efefef;
            border-radius: 2pt;
            text-align: center;
            font-weight: 600;
            padding: 0;
            font-size: 13px;
            line-height: 27.5px;
            margin: 0 2px !important;
        }
    }
}
