.StarButton {
    display: inline-flex !important;
    align-items: center;
    justify-content: flex-start;

    div {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 800;
        font-size: 15px;
        margin-right: 7px;

        ion-icon {
            color: #ff7a01;
            font-size: 18px;
            margin-right: 7px;
        }
    }

    span {
        color: var(--ion-color-medium);
        font-size: 13px;
    }
}
