.payment-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    border: 1px solid #ebebeb;
    border-radius: 2pt;
    overflow: hidden;
    width: 100%;
    color: var(--ion-color-medium-shade);
    font-size: 14px;
    padding: 15px;
    height: 100%;
    min-height: 125px;
    cursor: pointer;

    &.active {
        border-color: var(--ion-color-primary);
        color: var(--ion-color-primary);
    }

    &.disabled {
        cursor: default;
        opacity: .5;
    }

    img {
        height: 75px;
        display: flex;
        margin-bottom: 20px;
        width: 100%;
        object-fit: contain;
    }

    .error {
        margin-top: 5px;
        font-size: 12px;
    }
}
