.notification-item {
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 15px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: 1px solid rgba(var(--ion-color-medium-rgb), 0.2);

    &-title {
        display: grid;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        align-items: center;
    }

    &-inner {
        background: #FFFFFF;
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 15px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        display: grid;
        grid-template-columns: 35px 1fr 51px;
        grid-gap: 10px;
        align-items: center;
        border: 1px solid rgba(var(--ion-color-medium-rgb), 0.2);

        &-left {
            width: 35px;
            height: 35px;
            background: #f9f9f9;
            border-radius: var(--ion-border-radius);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            margin-right: 20px;
            color: #1E1E1E;
        }

        &-center {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }

        &-right {
            text-align: right;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
        }
    }
}
