.LoveButton {
    display: inline-flex !important;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;

    div {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    span {
        color: var(--ion-color-medium);
        font-size: 13px;
    }
}
