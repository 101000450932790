ion-menu {
    &[menu-id="foodDetailsMenuLeft"],
    &[menu-id="foodDetailsMenuRight"],
    &[menu-id="foodDetailsMenu"] {
        position: fixed;
        --width: 520px;
        top: 70px;
        height: calc(100vh - 70px);
        z-index: 740;
        will-change: transform;
    }
}

#FoodDetailsContent {
    --background: #fff;

    #closeFoodDetails {
        position: fixed;
        right: 35px;
        top: 25px;
        background: transparent;
        padding: 0;
        font-size: 40px;
        color: #fff;
        filter: drop-shadow(0 0 8px rgba(0,0,0,0.5));
    }

    .DetailsImage {
        height: 335px;
        width: 100%;
        background: var(--ion-color-medium);

        ion-skeleton-text {
            margin: 0;
        }

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;

            &.hasClick {
                cursor: zoom-in;
            }
        }
    }

    .DetailsContent {
        padding: 26px 30px;
        text-align: left;
        
        h2 {
            margin: 0 0 5px;
            text-align: left;
            font-family: 'Yanone Kaffeesatz', sans-serif;
            font-size: 38px;
            font-weight: 600;
            color: var(--ion-color-success);
        }

        h4 {
            margin: 0 0 20px;
            text-align: left;
            font-family: 'Yanone Kaffeesatz', sans-serif;
            font-size: 28px;
            font-weight: 600;
            color: var(--ion-color-success);
        }

        .Comment {
            margin: 20px 0;
            font-size: 16px;
            color: var(--ion-color-primary);
            font-weight: 400;
        }

        .categoryBadge {
            padding: 3px 8px;
            border-radius: 2pt;
            font-size: 14px;
            margin: 0 0 20px;
            display: inline-flex;
            font-weight: 700;
        }

        .DetailsText {
            margin: 16px 0;
        }

        p {
            font-size: 14px;
        }

        .Cart {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            background: #f5f5f5;
            border-radius: 2pt;
            padding: 10px 20px;
            
            &.InCart {
                background: #fffd04;
            }

            .ExtraCart {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .btn {
                    background: transparent !important;
                    color: #000 !important;
                    height: 37.5px;
                    font-size: 22px;
                }
    
                .Quantity {
                    height: 37.5px;
                    width: 45px;
                    border: 0;
                    background: transparent;
                    border-radius: 2pt;
                    margin: 0 2px;
                    text-align: center;
                    font-weight: 800;
                    padding: 0;
                    font-size: 18px;
                    line-height: 37.5px;
                }
            }

            .Price {
                color: #000;
                font-size: 22px;
                font-weight: 700;
            }
        }
    }
}
