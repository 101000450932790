.CartElement {
    display: grid;
    grid-template-columns: 20% 1fr;
    grid-gap: 0;
    background: #fff;
    border-radius: 2pt;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.05);
    min-height: 100px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;

    &.weekly {
        grid-template-columns: 1fr;
        border-left: 2px solid var(--ion-color-success);
        margin-left: 5px;

        .CategoryTitle {
            font-weight: 600;
            color: var(--ion-color-dark-shade);
            font-size: 16px;
            margin: 0;
            line-height: 22px;
        }

        .CategoryPrice {
            font-weight: 700;
            color: var(--ion-color-primary);
            font-size: 15px;
            margin: 5px 0 0;
            line-height: 22px;
        }

        ul {
            margin: 10px 0;
            padding: 0 10px 0 20px;

            li {
                font-size: 14px;

                span {
                    margin-right: 4px;
                    color: var(--ion-color-success);
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }

    .CartElementImg {
        display: flex;
        max-height: 100%;
        background-size: cover !important;
        background-position: 50% 50% !important;
    }

    .CartElementContent {
        padding: 15px 50px 15px 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-flow: column;

        .FoodTitle {
            font-weight: 600;
            color: var(--ion-color-dark-shade);
            font-size: 16px;
            margin: 0;
            line-height: 22px;
        }

        .FoodPrice {
            font-weight: 700;
            color: var(--ion-color-primary);
            font-size: 15px;
            margin: 5px 0 0;
            line-height: 22px;
        }
    }

    .CartElementQuantity {
        position: absolute;
        top: 50%;
        right: 15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 33.3% 33.3% 33.3%;
        grid-gap: 0;
        height: 85px;
        width: 27px;
        transform: translateY(-50%);
        border-radius: 10pt;
        overflow: hidden;
        background: var(--ion-color-primary);

        .Quantity {
            background: var(--ion-color-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
        }

        .btn {
            border-radius: 0;
            width: 100%;
            padding: 10px 0;
            background: transparent;
        }
    }

    &.SuggestionElement {
        width: 100%;
        height: 100%;
        grid-template-columns: 30% 1fr;
        margin: 0;
        border-radius: 0;

        .CartElementContent {
            padding: 15px 20px 15px 20px;

            .FoodTitle {
                text-align: left;
            }
        }
    }
}

.suggestionSlide {
    position: relative;

    .arrowButton {
        background: var(--ion-color-primary);
        color: #fff;
        height: 30px;
        width: 30px !important;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .25);
        pointer-events: block;
        position: absolute;
        top: calc(50% - 25px);
        z-index: 50;

        &.arrowLeft {
            left: -8px;
        }

        &.arrowRight {
            right: -8px;
        }
        
        &:hover {
            opacity: .7;
        }
        
        ion-icon {
            font-size: 16px;
        }
    }
}

.swiper-slide {
    height: auto !important;
    padding: 10px 0 !important;
}
