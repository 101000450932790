.message-modal {
    width: 100%;

    .messages {
        padding: 45px 35px;

        .message-wrapper {
            border-left: 3px solid var(--ion-color-primary);
            padding: 10px 0 10px 30px;
            margin-bottom: 20px;

            h3 {
                font-family: 'Yanone Kaffeesatz', sans-serif;
                font-size: 38px;
                font-weight: 600;
                color: var(--ion-color-success);
                text-transform: uppercase;
                margin: 0 0 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
    
            div {
                font-size: 16px;
                font-weight: 400;
                color: var(--ion-color-medium);
                margin: 0 0 15px;
                line-height: 26px;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}
