ion-fab {
    display: none;
}

#HeaderBottomNavigation {
    display: none;
}

#mobileLogo {
    display: none;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

@media (max-width: 1919px) {
    #CategoryGroupSidebar {
        transform: translateX(-135px);
        width: 150px;
        overflow: hidden;
        background: transparent;
        box-shadow: none;
        transition: all .2s ease;
        opacity: 1;
        z-index: 350;
        border-top-right-radius: 2pt;
        border-bottom-right-radius: 2pt;
        background: #fff;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);

        .CategoryGroup  {
            .dot {
                width: 100%;
                border-radius: 0 !important;
            }
        }

        &.sticky {
            top: 75px !important;
        }

        &:hover {
            transform: translateX(0);
            opacity: 1;
            transition: all .2s ease;

            .CategoryGroup {
                .dot {
                    width: 5px;
                }

                &.is-current,
                &:hover {
                    .dot {
                        width: 100%;
                    }
                }
            }
        }
    }

    #mobileMenuFab {
        display: none;
    }
}

@media (max-width: 1600px) {
    .Container {
        max-width: 97%;
    }

    .Day {
        .Date {
            span {
                font-size: 12px;
            }

            small {
                font-size: 9px;
            }
        }
    }
}

/* ------------------------------ */
// Laptop
/* ------------------------------ */
@media (max-width: 1444px) {
    #HeaderBottom .DaysRow .Days .Day .Price span {
        font-size: 11px;
    }

    #HeaderBottom .DaysRow .Days .Day .Date {
        font-size: 14px;
    }

    #HeaderBottom .DaysRow .filter-button span {
        font-size: 14px;
    }

    .Category .CategoryCode, .Category .CategoryName {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
    }

    .Foods .Food .Title {
        font-size: 11px;
    }

    ion-modal.modal-large {
        --width: 90vw;
        --height: 90vh;
    }

    #mobileMenuFab {
        display: none;
    }

    ion-menu[menu-id="cartDetailsMenu"],
    ion-menu[menu-id="foodDetailsMenuLeft"],
    ion-menu[menu-id="foodDetailsMenuRight"],
    ion-menu[menu-id="foodDetailsMenu"] {
        --width: 45vw;
        --min-width: auto;
    }
}


/* ------------------------------ */
// Tablet
/* ------------------------------ */

@media (max-width: 1025px) {
    .Container {
        max-width: 100%;
    }

    #Header .HeaderTop button span {
        display: none;
    }

    .Category {
        display: grid;
        grid-template-columns: 5% 15% 1fr;
        grid-gap: 2px;
        height: auto;
        margin-bottom: 2px;
    }

    .Foods {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2px;
    }

    #HeaderBottom .DaysRow {
        grid-template-columns: calc(20% + 2px) 1fr;
    }

    #HeaderBottom .DaysRow .Days {
        grid-template-columns: repeat(3, 1fr)
    }

    .CategoryBanner {
        grid-template-columns: 1fr;

        div {
            display: none;

            &.CategoryBannerText {
                display: flex;
            }
        }
    }

    #HeaderBottomNavigation {
        display: block;
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1px;
        background: var(--ion-color-primary);

        button {
            background: var(--ion-color-primary-shade);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            ion-icon {
                margin-left: 10px;
            }

            &:first-of-type {
                ion-icon {
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
    }

    #HeaderBottom {
        height: 150px;
    }

    #TopBanner {
        height: 150px;

        &.sticky {
            margin-bottom: 152px;
        }
    }

    #HeaderBottom .OldMenuRow a {
        font-size: 13px;
    }

    ion-fab {
        display: block;
        z-index: 99;
        position: fixed;
        
        ion-fab-button {
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
            border-radius: 50%;
        }
    }

    #mobileMenuFab {
        display: none;
    }

    #CategoryGroupSidebar {
        background: #fff;
        z-index: 9999;
        width: 250px;
        position: fixed;
        bottom: 0 !important;
        height: calc(100% - 80px) !important;
        top: auto !important;
        left: 0;
        transform: translateX(0px);
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        opacity: 1;
        transform: translateX(-250px);
        overflow-y: auto;

        .CategoryGroup {
            .dot {
                width: 5px;
            }
        }

        &[data-open="open"] {
            transform: translateX(0);

            .CategoryGroup {
                .dot {
                    width: 5px;
                }

                &.is-current {
                    .dot {
                        width: 100%;
                    }
                }
            }
        }

        &:hover {
            transform: translateX(-250px);
        }
    }

    ion-menu[menu-id="cartDetailsMenu"],
    ion-menu[menu-id="foodDetailsMenuLeft"],
    ion-menu[menu-id="foodDetailsMenuRight"],
    ion-menu[menu-id="foodDetailsMenu"] {
        --width: 60vw;
        --min-width: auto;
    }
}


/* ------------------------------ */
// Mobil
/* ------------------------------ */

@media (max-width: 600px)  {
    #Header .HeaderTop button#headerOrdersButton {
        display: none;
    }
    
    .Category {
        display: grid;
        grid-template-columns: 12% 24% 1fr;
        grid-gap: 2px;
        height: auto;
        margin-bottom: 2px;
    }

    .Foods {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2px;
    }

    #HeaderBottom .DaysRow {
        grid-template-columns: calc(36% + 2px) 1fr;
    }

    #HeaderBottom .DaysRow .Days {
        grid-template-columns: repeat(1, 1fr)
    }

    #HeaderBottom .OldMenuRow > a {
        display: none;
    }

    #HeaderBottom .OldMenuRow .dropdown {
        max-width: 100%;
    }

    #HeaderBottom .OldMenuRow .dropdown.dropdown--active .dropdown__content {
        width: 100%;
    }

    ion-menu[menu-id="cartDetailsMenu"],
    ion-menu[menu-id="foodDetailsMenuLeft"],
    ion-menu[menu-id="foodDetailsMenuRight"],
    ion-menu[menu-id="foodDetailsMenu"] {
        --width: 90vw;
        --min-width: auto;
    }

    #Header .HeaderTop .header-left > img {
        display: none;
    }

    #Header .HeaderTop .header-left img {
        padding: 0 5px;
    }

    .Container footer {
        text-align: center;
        font-size: 14px;
        flex-flow: column;

        & > .footer-row {
            margin-bottom: 25px;
        }
    }

    #TopBanner {
        height: 120px;
    }

    ion-modal.modal-large {
        --width: 100vw;
        --height: 100%;
        --border-radius: 0;
    }

    ion-modal.modal-fullscreen .modal-wrapper {
        max-height: 100%;
    }

    .profile-tabs {
        display: none;
        &.showTabs {
            position: absolute;
            max-height: 100%;
            height: 100%;
            max-width: 100vw !important;
            width: 100vw !important;
            flex: auto !important;
            padding: 25px !important;
            z-index: 9999;
            display: block;
        }
    }

    .profile-modal .profile-tab-content {
        max-height: 100%;
        height: 100%;
        max-width: 100vw !important;
        width: 100vw !important;
        flex: auto !important;
        padding: 25px !important;
    }

    .orderData .orderData-div {
        grid-template-columns: repeat(2, 1fr);

        .multiLine.details {
            grid-column: span 2/auto;
        }
    }

    ion-modal.modal-fullscreen .modal-close {
        top: 15px;
        right: 15px;
        width: 35px;
        height: 35px;
    }

    .boxRadio.twoColumns {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(200px, 1fr);
    }

    .boxRadio {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(100px, 1fr);
    }

    .modal-with-image ion-row ion-col.fullHeight {
        padding: 25px;
        height: auto;
        min-height: 100%;
    }

    .modal-with-image ion-row ion-col.fullHeight ion-row.modal-footer {
        font-size: 13px;
    }

    .modal-with-image ion-row ion-col.fullHeight .form .FormInline ion-col:last-of-type,
    .modal-with-image ion-row ion-col.fullHeight .form .FormInline ion-col:first-of-type {
        padding: 0;
    }

    ion-card.centerCard {
        max-width: 90vw;
        margin: 100px auto 25px;
    }

    .message-modal {
        .modal-wrapper {
            height: 70vh;
            width: 80vw;

            .messages {
                padding: 45px 35px;

                .message-wrapper {
                    border: 0;
                    padding: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }

    #cityLogo,
    #vitalkonyhaLogo,
    #paleoLogo {
        display: none;
    }

    #mobileLogo {
        display: flex;

        .dropdown {
            position: relative;

            .dropdown__trigger {
                div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

            .dropdown__content {
                background: #fff;
                box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .1);
                width: 100%;

                .logos {
                    ion-router-link {
                        display: flex;
                        border-bottom: 1px solid #ebebeb;
                        align-items: center;
                        justify-content: center;

                        &:last-of-type {
                            border: 0;
                        }
                    }
                }
            }
        }
    }

    #mobileMenuFab {
        display: block;
        z-index: 99;
        position: fixed;
    }

    .VitalTop,
    .PaleoTop {

        & > img {
            max-width: 150%;
            width: 150%;
            margin-left: -25%;
        }

        .inner {
            padding: 10px 25px;
        }

        .check-grid {
            grid-template-columns: repeat(1, 1fr);

            span {
                font-size: 24px;
                font-family: "Yanone Kaffeesatz";
                font-weight: 700;
                color: #444;
                align-items: center;
                display: grid;
                grid-template-columns: 50px 1fr;
                justify-content: flex-start;

                ion-icon {
                    margin: 0;
                    font-size: 25px;
                }
            }
        }
    }

    .VitalBottom {
        padding: 10px 25px;

        img {
            max-width: 170px;
            height: auto;
        }
    }

    .Foods .Food {
        .Title {
            font-size: 14px;
        }
        
        .Price {
            font-size: 14px;
        }

        .Extras .ExtraCart .Quantity {
            font-size: 14px;
        }
    }

    #HeaderBottom .DaysRow .filters {
        padding: 15px;
        max-height: calc(100vh - 210px);
        overflow-y: scroll;
    }

    #gdrpAlert {
        flex-flow: column;
        position: fixed;
        bottom: 20px;
        left: 20px;
        background: #fff;
        padding: 20px;
        width: calc(100% - 40px);
        z-index: 9999999;

        .buttons {
            margin-top: 15px;
        }

        p {
            font-size: 15px;
        }
    }

    #HeaderBottom .DaysRow .filters-template-buttons {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 0 15px;
    }

    #HeaderBottom .DaysRow .filter-button span ion-icon {
        margin-right: 5px;
        font-size: 16px;
    }

    #CartDetailsContent {
        .content {
            padding: 10px 10px;

            .CartWeekHolder {
                .CartDaysHolder {
                    padding: 10px 0 10px 10px;
                    margin-left: 5px;
            
                    .CartDay {
                        .CartDayHolder {
                            border-left: 2px solid var(--ion-color-dark-shade);
                            padding: 10px 0 10px 10px;
                            margin-bottom: 15px;
                            margin-left: 5px;

                            .CartElement {
                                .CartElementContent {
                                    .FoodTitle {
                                        font-size: 14px;
                                        margin: 0;
                                        line-height: 18px;
                                    }

                                    .categoryBadge {
                                        font-size: 10px;
                                    }
                                }

                                .CartElementQuantity {
                                    right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
