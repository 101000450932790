.PaleoBottom {
    text-align: left;
    background: #fff;
    border-radius: 2pt;
    overflow: hidden;

    a {
        display: flex;

        img {
            width: 100%;
        }
    }
}
