#CategoryGroupSidebar {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    padding: 0;
    width: calc((100% - 1554px) / 2);
    max-width: 200px;
    will-change: transform;

    &.sticky {
        position: fixed;
        top: 172px;
    }

    div {
        display: flex;
        margin: 0;
        padding: 0;
        flex-flow: column;
        overflow: hidden;

        a {
            position: relative;
            padding: 9px 10px;
            cursor: pointer;
            text-decoration: none;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .dot {
                position: absolute;
                display: inline-flex;
                width: 5px;
                border-radius: 0;
                left: 0;
                top: 0;
                height: 100%;
                transition: all 0.15s ease;
                will-change: transform;
            }

            span {
                margin-left: 10px;
                font-size: 13px;
                color: var(--ion-color-dark-shade);
                font-weight: 700;
                text-transform: uppercase;
                transition: all 0.15s ease;
                z-index: 50;
                will-change: transform;
            }

            &.is-current,
            &:hover {
                .dot {
                    width: 100%;
                    border-top-right-radius: 2pt;
                    border-bottom-right-radius: 2pt;
                }

                span {
                    color: #fff !important;
                }
            }
        }
    }
}
