.modal-with-image ion-row {
    height: 100%;

    ion-col.fullHeight {
        position: relative;
        padding: 40px 100px;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-flow: column;

        .form {
            width: 100%;

            .FormInline {
                ion-col {
                    padding-top: 0;
                    padding-bottom: 0;

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }

        &.hasFooter {
            padding: 40px 100px 105px;
        }

        .modal-title {
            font-family: 'Yanone Kaffeesatz', sans-serif;
            font-size: 48px;
            font-weight: 600;
            color: var(--ion-color-success);
            text-transform: uppercase;
            margin: 0 0 10px;
        }

        .modal-subtitle {
            font-size: 16px;
            font-weight: 400;
            color: var(--ion-color-medium);
            margin: 0 0 25px;
            line-height: 26px;
        }

        ion-row.modal-footer {
            position: absolute;
            height: auto;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 65px;
            border-top: 1px solid #d0d0d0;

            ion-col {
                padding: 0;
                height: 100%;

                &:first-of-type {
                    border-right: 1px solid #d0d0d0;
                }

                a {
                    display: grid;
                    width: 100%;
                    height: 100%;
                    background: #ececec;
                    align-items: center;
                    justify-self: center;
                    color: #6f6f6f;
                    text-align: center;
                    text-decoration: none;
                }
            }
        }

        &.overflow {
            overflow-y: auto;
            display: block;
        }
    }
}

#gdprModal,
#pinModal,
#ratingModal,
#loginModal {
    .hasBg {
        background: #fff url("../../assets/images/loginBg.jpg") 50% 50% no-repeat;
        background-size: cover;
        padding: 0 !important;
        align-items: center !important;
    }
}

#loginModal {
    #mobile_reg {
        ion-text {
            margin: 10px 0;
            display: block;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

#orderDuplicateModal,
#reorderModal,
#addressModal,
#lostPassModal,
#regModal {
    .hasBg {
        background: #fff url("../../assets/images/lostpasswordBg.jpg") 50% 50% no-repeat;
        background-size: cover;
        padding: 0 !important;
        align-items: center !important;
    }
}
