ion-menu {
    &[menu-id="cartDetailsMenu"] {
        position: fixed;
        --width: 27.5vw;
        --min-width: 500px;
        top: 70px;
        height: calc(100% - 70px);
        z-index: 740;
        will-change: transform;
    }
}

#CartDetailsContent {
    //--background: #f9f9f9;
    --background: #fff;

    .content {
        position: relative;
        padding: 30px 20px;
        max-height: calc(100% - 125px);
        overflow-y: auto;

        .emptyCart {
            padding: 25px 0 0;
            h4 {
                font-size: 18px;
                font-weight: 700;
                color: var(--ion-color-dark-shade);
                text-align: center;
                line-height: 1.5;
            }

            p {
                font-size: 15px;
                font-weight: 300;
                color: var(--ion-color-medium);
                line-height: 25px;
                text-align: center;
                margin: 0 auto;
                max-width: 70%;
            }
        }

        h1 {
            margin: 0 0 30px;
            font-family: 'Yanone Kaffeesatz', sans-serif;
            font-size: 36px;
            text-align: left;
            font-weight: bold;
            font-style: normal;
            line-height: 50px;
            margin-bottom: 15px;
            color: var(--ion-color-dark-shade);
        }

        .categoryBadge {
            padding: 3px 8px;
            border-radius: 2pt;
            font-size: 12px;
            margin: 5px 0;
            display: inline-flex;
            font-weight: 700;
        }

        .CartWeekHolder {
            &:first-of-type {
                margin: 0;
            }

            .Title {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                h4 {
                    margin: 0 0 15px;
                    font-size: 20px;
                    font-weight: 700;
                    color: var(--ion-color-primary);
                    display: inline-flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-flow: column;
    
                    span {
                        color: var(--ion-color-primary);
                        font-size: 10.5px;
                        font-weight: 600;
                        margin-top: 5px;
                        opacity: .7;
                    }
                }

                h5 {
                    margin: 0 0 15px;
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--ion-color-primary-shade);
                    display: inline-flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-flow: column;
                }

                div {
                    h5,
                    h4 {
                        align-items: flex-end;
                        text-transform: none;
                    }
                }
            }

            .CartDaysHolder {
                border-left: 2px solid var(--ion-color-primary);
                padding: 10px 0 10px 20px;
                margin-left: 5px;

                .CartDay {
                    .CartDayHolder {
                        border-left: 2px solid var(--ion-color-dark-shade);
                        padding: 10px 0 10px 20px;
                        margin-bottom: 15px;
                        margin-left: 5px;
                    }

                    .Title {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        margin-bottom: 15px;

                        h4 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 700;
                            color: var(--ion-color-dark-shade);
                            text-transform: capitalize;
                            display: inline-flex;
                            align-items: flex-start;
                            justify-content: center;
                            flex-flow: column;
            
                            span {
                                font-size: 11px;
                                font-weight: 600;
                                color: var(--ion-color-dark-tint);
                                margin-top: 5px;
                                opacity: .7;
                            }
                        }
                        
                        div {
                            h4 {
                                align-items: flex-end;
                                text-transform: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .CartCheckoutSticky {
        position: fixed;
        bottom: 0;
        right: 0;
        background: #fff;
        border-top: 1px solid #ebebeb;
        width: 100%;
        height: 125px;
        padding: 15px 25px;
        z-index: 10; /** this value affects the overlapping **/

        h4 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 700;
            color: var(--ion-color-dark);
            line-height: 25px;
            margin: 0 0 15px;

            span {
                color: var(--ion-color-primary);
                font-weight: 700;
            }
        }
        
    }
}
