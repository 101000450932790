.foodIcon {
    text-align: center;
    font-size: 12px;
    font-weight: 500;

    &.foodIconFloat {
        float: right;
        display: block;
    }
}

.Foods {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2px;

    &.lastMinute {
        grid-template-columns: repeat(1, 1fr);
    }

    .Food {
        color: #444;
        border-radius: 2pt;
        background: #fff;
        flex: 1;
        position: relative;
        padding: 10px 15px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5px;
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        min-height: 115px;
        overflow: hidden;

        &.LastMinute {
            border: 2px solid var(--ion-color-primary);
        }

        .lastMinuteBar {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 16px;
            width: 16px;
            font-size: 13px;
            border-top-left-radius: 2pt;
            background: var(--ion-color-primary);
            color: var(--ion-color-primary-contrast);
        }

        &.EmptyFood {
            opacity: .7;
        }

        &.InCart {
            background: #fffd04;
        }

        .TopIcons {
            text-align: center;
        }

        .Title {
            margin: 0;
            text-align: left;
            font-size: 12px;
            font-weight: 500;
        }

        .Details {
            margin: 0;
            text-align: left;
            font-size: 12px;
            color: var(--ion-color-primary);
            font-weight: 500;
        }

        .Price {
            margin: 0;
            text-align: right;
            font-size: 12px;
            font-weight: 700;
        }

        .Date {
            margin: 0;
            text-align: left;
            font-size: 12px;
            font-weight: 700;
        }

        .Extras {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0;
            align-items: flex-end;

            .ExtraButtons {
                display: flex;
                justify-content: flex-start;

                .LoveButton {
                    background: transparent !important;
                    margin-left: 5px; 

                    ion-icon {
                        margin: 0;
                    }
                }
            }

            .ExtraCart {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .Quantity {
                    height: 27.5px;
                    width: 35px;
                    border: 0;
                    background: #efefef;
                    border-radius: 2pt;
                    margin: 0 2px;
                    text-align: center;
                    font-weight: 600;
                    padding: 0;
                    font-size: 13px;
                    line-height: 27.5px;
                }
            }

            button {
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}


.LastMinutePage {
    .Food.LastMinute {
        border: 0;
        min-height: auto;
        display: grid;
        grid-template-columns: 1fr 80px 140px 170px;
        grid-gap: 10px;
        align-items: center;

        .Details,
        .BottomIcons,
        .lastMinuteBar,
        .foodIcon,
        .TopIcons {
            display: none;
        }
    }
}
