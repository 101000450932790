.profile-modal {
    width: 100%;

    ion-row {
        overflow: hidden;
    }

    .profile-tabs {
        background: #fafafa;
        padding: 15px !important;
        border-right: 1px solid #ebebeb;

        .profile-tabs-wrapper {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;

            button {
                display: flex;
                position: relative;
                width: 100%;
                flex: 1 1 auto;
                font-size: 16px;
                font-weight: 600;
                line-height: 46px;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
                background: transparent;
                padding: 0 15px;
                color: var(--ion-color-dark);
                border-radius: 2pt;
                transition: all .15s ease;

                div.iconHolder {
                    width: 50px;
                    height: 50px;
                    border-radius: 0;
                    margin-right: 25px;
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background: var(--ion-color-success);
                }

                /*&#tabButtonfavourites {
                    div.iconHolder {
                        background: #fcebeb;
                        color: #e87369;
                    }
                }

                &#tabButtonLogout {
                    div.iconHolder {
                        background: #ddedfb;
                        color: #507efe;
                    }
                }

                &#tabButtonratings {
                    div.iconHolder {
                        background: #faf2de;
                        color: #ffcc01;
                    }
                }

                &#tabButtonnotifications {
                    div.iconHolder {
                        background: #f1ddfb;
                        color: #a863b9;
                    }
                }

                &#tabButtonaddresses {
                    div.iconHolder {
                        background: #fbddef;
                        color: #c781b2;
                    }
                }

                &#tabButtonorders {
                    div.iconHolder {
                        background: #ddfbde;
                        color: #6cb963;
                    }
                }*/

                &:hover {
                    opacity: .6;
                    transition: all .15s ease;
                }

                .arrow {
                    right: 15px;
                    position: absolute;
                    transform: translate(-15px, 0);
                    transition: all .15s ease;
                    font-size: 18px;
                }

                &:hover,
                &.active {
                    .arrow {
                        transform: translate(0, 0);
                        transition: all .15s ease;
                    }
                }

                &.active {
                    background: #e6e6e6;
                    border-radius: 2pt;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .profile-tab-content {
        padding: 50px !important;
        align-items: flex-start;
        justify-content: flex-start  !important;
        max-height: 660px;
        height: 70vh;
        overflow-y: auto;

        h1 {
            font-family: 'Yanone Kaffeesatz', sans-serif;
            font-size: 48px;
            font-weight: 600;
            color: var(--ion-color-success);
            text-transform: uppercase;
            margin: 0 0 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            ion-button {
                --padding-top: 10px;
                --padding-bottom: 10px;
                height: 30px;
                margin: 0 0 6px 20px;
            }
        }

        .modal-subtitle {
            font-size: 16px;
            font-weight: 400;
            color: var(--ion-color-medium);
            margin: 0 0 25px;
            line-height: 26px;
        }

        h2 {
            margin: 0 0 20px;
            font-size: 14px;
            color: var(--ion-color-dark-shade);
            font-weight: 700;
            text-transform: uppercase;
        }

        .favourites {
            .CartElementQuantity {
                grid-template-rows: 100%;
                height: 27px;
            }
        }

        .orderData-wrapper {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
        }
    }

    .persons-tab {
        .persons {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 15px;
        }
    }
}

.person {
    border-radius: 2pt;
    padding: 20px 15px;
    background: #fff;
    display: flex;
    flex-flow: column;
    align-items: center;
    border: 1px solid #ebebeb;

    &.active {
        border-color: var(--ion-color-primary);
    }

    &-avatar {
        width: 100px;
        height: 100px;
        color: #fff;
        font-size: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 15px;
        line-height: 0px;
        text-transform: uppercase;
    }

    &-name {
        font-size: 18px;
        font-weight: 600;
    }

    ion-button {
        margin-top: 15px;
    }
}
