#Header {
    background: #fff;
    color: #000;
    border-radius: 0;
    margin: 0;
    height: auto;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 750;
    padding: 0;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    will-change: transform;

    .HeaderTop {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            border-left: 1px solid #ebebeb;
            width: auto;
            height: 100%;
            background: #fff;
            color: var(--ion-color-primary);
            font-size: 26px;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 10px;
                font-size: 12px;
                color: var(--ion-color-dark-shade);
                font-weight: 700;
                text-transform: uppercase;

                &.badge {
                    background: var(--ion-color-primary);
                    color: #fff;
                    font-size: 12px;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    right: 14px;
                    top: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                }

                &.title {
                    font-weight: 300;
                    margin: 0;
                }
            }

            ion-icon {
                &.chevron {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .header-left {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-flow: row;
            height: 100%;

            img {
                display: flex;
                max-height: 70px;
                height: 100%;
                padding: 0 20px;
                filter: grayscale(1);
                transition: filter 0.35s ease;

                &:hover,
                &.active {
                    filter: grayscale(0);
                    transition: filter 0.35s ease;
                }
            }

            .weeks {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                flex-flow: row;
                height: 100%;

                .week-button {
                    border-left: 1px solid #ebebeb;
                    width: auto;
                    height: 100%;
                    background: #fff;
                    color: var(--ion-color-primary);
                    font-size: 26px;
                    padding: 0 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;

                    span {
                        margin: 0;
                        font-size: 14px;
                    }

                    &.active {
                        background: var(--ion-color-primary);

                        span {
                            color: #fff;
                        }
                    }

                    &:last-of-type {
                        border-right: 1px solid #ebebeb;
                    }
                }
            }
        }

        .header-right {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-flow: row;
            height: 100%;
        }
    }
}

#HeaderBottom {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    background: var(--ion-color-primary);
    color: #000;
    border-radius: 0;
    margin: 0;
    position: relative;
    width: 100%;
    height: 100px;
    padding: 0;
    
    &.noDates {
        height: 50px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    }

    &.sticky {
        position: fixed;
        width: 100%;
        top: 70px;
        z-index: 260;
    }

    .OldMenuWrapper,
    .DaysRowWrapper {
        width: 100%;
        height: 50px;
    }

    .Container {
        margin: 0 auto;
    }

    .DaysRow {
        position: relative;
        display: grid;
        grid-template-columns: calc(13.5% + 2px) 1fr;
        grid-gap: 2px;
        width: 100%;
        height: 100%;
        background: var(--ion-color-primary-shade);

        button {
            background: var(--ion-color-primary-shade);
            height: 100%;
            width: 100%;
            color: #fff;
            font-size: 26px;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                margin-left: 10px;
                font-size: 14px;
                color: #fff;
                font-weight: 800;
                text-transform: uppercase;
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .Days {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 2px;
            height: 100%;

            .Day {
                position: relative;
                display: flex;
                align-items: center;
                background: var(--ion-color-primary);

                .Date {
                    color: #fff;
                    font-weight: 700;
                    font-size: 14px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-flow: column;
                    padding: 0 0 0 20px;
                    text-transform: capitalize;

                    small {
                        font-weight: 300;
                    }
                }

                .Price {
                    color: #fff;
                    font-weight: 800;
                    font-size: 14px;
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-flow: column;
                    padding: 0 20px 0 0;

                    span {
                        background: #fff;
                        padding: 0 10px;
                        color: var(--ion-color-primary);
                        border-radius: 2pt;
                    }
                }
            }
        }
    }

    .OldMenuWrapper {
        background: #ebeae8;
    }

    .OldMenuRow {
        display: flex;
        width: 100%;
        height: 100%;
        flex-flow: row;

        .dropdown {
            display: flex;
            flex: 1 1 auto;
            background: var(--ion-color-success);
            max-width: 350px;
            cursor: pointer;

            &.dropdown--active {
                ion-icon {
                    transform: rotate(180deg);
                }

                .dropdown__content {
                    top: 50px;
                    background: #fff;
                    z-index: 999;
                    width: 350px;
                    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);

                    .week-button {
                        padding: 20px 25px;
                        border-bottom: 1px solid #ebebeb;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-right: 0;

                        .week {
                            font-size: 16px;
                            font-weight: 600;
                            color: var(--ion-color-primary);
                            font-weight: 800;
                            text-transform: uppercase;
                        }
                        
                        &.disabled {
                            pointer-events: none !important;
                            
                            .week {
                                color: var(--ion-color-medium);
                            }
                        }

                        .dates {
                            font-size: 13px;
                            font-weight: 400;
                            color: var(--ion-color-medium-shade);
                        }

                        &:last-of-type {
                            border: 0;
                        }
                    }
                }
            }
        }

        .filter-button {
            background: transparent;
            text-decoration: none;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;

            span {
                font-size: 12px;
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
            }

            div {
                b {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    font-weight: 800;
                    margin-right: 10px;
                    text-transform: uppercase;
                }

                ion-icon {
                    color: #fff;
                    font-size: 12px;
                }
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .last-minute {
            background: #bc1b21;
            color: #ffffff;
        }

        a {
            position: relative;
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: center;
            background: transparent;
            color: var(--ion-color-medium);
            text-decoration: none;
            border-right: 1px solid #d6d6d6;
            font-size: 15px;
            font-weight: 600;

            &:hover {
                opacity: 0.6;
            }
        }
    }

    .DaysRow {
        .filter-button {
            background: transparent;
            text-decoration: none;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 0;
            width: 100%;

            span {
                font-size: 12px;
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
                display: flex;

                ion-icon {
                    margin-right: 7px;
                    font-size: 18px;
                }
            }

            div {
                b {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    font-weight: 800;
                    margin-right: 10px;
                    text-transform: uppercase;
                }

                ion-icon {
                    color: #fff;
                    font-size: 12px;
                }
            }

            &:hover {
                opacity: 0.6;
            }
        }

        .dropdown {
            display: flex;
            flex: 1 1 auto;
            background: var(--ion-color-primary-shade);
            height: 100%;
            cursor: pointer;

            &.dropdown--active {
                .filter-button div ion-icon {
                    transform: rotate(180deg);
                }

                .dropdown__content {
                    top: 50px;
                    background: #fff;
                    z-index: 999;
                    width: 100%;
                    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
                    text-align: left;
                }
            }
        }

        .filters {
            padding: 25px;
            position: relative;

            h4 {
                margin: 0 0 15px;
                font-size: 14px;
                color: var(--ion-color-dark-shade);
                font-weight: 700;
                text-transform: uppercase;
            }

            &-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
            }

            &-default {
                color: var(--ion-color-danger);
                text-decoration: underline;
                font-size: 11px;
                
                &:hover {
                    opacity: .7;
                }
            }

            &-template-buttons {
                position: absolute;
                top: 10px;
                right: 10px;
                
                ion-button {
                    font-size: 11px;
                    height: 30px;

                    ion-icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

#TopBanner {
    width: 100%;
    height: 300px;
    background: #ebeae8;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: flex;
        object-fit: cover;
        height: 100%;
        width: 100%;

        &.mobile-top {
            display: none;
        }
    }

    &.sticky {
        margin-bottom: 102px;
    }
}

@media screen and (max-width: 600px) {
    #TopBanner {
        img {
            &.mobile-top {
                display: flex;
            }

            &.desktop-top {
                display: none;
            }
        }
    }

    #HeaderBottom {
        .DaysRow {
            .filters-grid {
                grid-template-columns: repeat(1, 1fr);

                ion-range {
                    padding: 0;
                }
            }
        }
    }
}


ion-range {
    ion-label {
        min-width: 50px;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0.7;
    }
}
