#reorderModal {
    .form {
        .category-groups {
            position: relative;
            display: block;
            width: 100%;

            ion-content {
                max-height: 300px;
                height: 300px;
            }
    
            .reorder-enabled ion-reorder {
                color: #fff;
                opacity: 1;
            }
        }
    }
}
