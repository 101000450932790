.IconExplain {
    text-align: center;
    background: #fff;
    border-radius: 2pt;
    overflow: hidden;
    font-size: smaller;

    ion-col {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 80px;

        &.ion-text-start {
            img {
                margin-right: 8px;
            }
        }
    }

    .star {
        color: red;
    }
}
