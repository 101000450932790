#gdrpAlert {
    position: fixed;
    bottom: 40px;
    left: 40px;
    background: #fff;
    padding: 20px 40px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, .2);
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin: 0;
        font-size: 16px;
    }
}
