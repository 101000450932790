/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/*@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../assets/fonts/OpenSans-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../assets/fonts/OpenSans-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../assets/fonts/OpenSans-SemiBold.ttf') format('ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../assets/fonts/OpenSans-Bold.ttf') format('ttf');
}*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Yanone+Kaffeesatz:wght@300;400;600;700&display=swap');

* {
    font-family: 'Open Sans', sans-serif;
}

:root {
    --ion-color-primary: #d61f25;
    --ion-color-primary-rgb: 214,31,37;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #bc1b21;
    --ion-color-primary-tint: #da353b;
  
    --ion-color-secondary: #81c02f;
    --ion-color-secondary-rgb: 129,192,47;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 0,0,0;
    --ion-color-secondary-shade: #72a929;
    --ion-color-secondary-tint: #8ec644;
  
    --ion-color-tertiary: #f2cb13;
    --ion-color-tertiary-rgb: 242,203,19;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #d5b311;
    --ion-color-tertiary-tint: #f3d02b;
  
    --ion-color-success: #81c02f;
    --ion-color-success-rgb: 129,192,47;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #72a929;
    --ion-color-success-tint: #8ec644;
  
    --ion-color-warning: #f2cb13;
    --ion-color-warning-rgb: 242,203,19;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #d5b311;
    --ion-color-warning-tint: #f3d02b;
  
    --ion-color-danger: #d61f26;
    --ion-color-danger-rgb: 214,31,38;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #bc1b21;
    --ion-color-danger-tint: #da353c;
  
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,36,40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    --ion-color-medium: #a1a1a1;
    --ion-color-medium-rgb: 161,161,161;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #8e8e8e;
    --ion-color-medium-tint: #aaaaaa;
  
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255,255,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;
}

.ios body {
    background: #e6e6e6;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    overflow: auto;
    height: auto;
    position: relative;

    &.backdrop-no-scroll {
        overflow: hidden !important;
    }
}

button {
    &:focus {
        outline: none;
    }
}

ion-button {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 46px;
    --border-radius: 2pt;
    --padding-top: 15px;
    --padding-bottom: 15px;
    height: 50px;
}

ion-input {
    --padding-top: 0;
    --padding-bottom: 0;
}

.btn {
    background-color: var(--ion-color-success);
    border: none;
    color: #fff;
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    font-size: 18px;
    border-radius: 2pt;
    align-items: center;
    justify-content: center;

    &.btn-sm {
        padding: 3px 5px;
    }

    &.btn-primary {
        background-color: var(--ion-color-success);
    }

    &.btn-danger {
        background-color: var(--ion-color-danger);
    }
    
    &.btn-warning {
        background-color: var(--ion-color-warning);
    }

    &.btn-medium {
        background-color: var(--ion-color-medium);
    }

    &.btn-light {
        background-color: var(--ion-color-light);
        color: var(--ion-color-light-contrast);
    }

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: .8;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &.hasIcon {
        ion-icon {
            margin-right: 5px;
        }
    }
}

.dropdown {
    display: inline-block;

    .dropdown__content {
        display: none;
        position: absolute;
    }

    &.dropdown--active {
        .dropdown__content {
            display: block;
        }
    }
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    background: #fff;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
    border: 1px solid #d6d6d6;

    * {
        position: relative;
    }

    td,
    th {
        text-align: left;
        padding: 8px 10px;
    }

    thead {
        border-bottom: 1px solid #d6d6d6;
        
        tr {
            th {
                color: #5f5f5f;
                font-size: 12px;
                font-weight: 800;
                text-transform: uppercase;
                margin: 0 0 4px;
                opacity: .8;
            }
        }
    }

    tbody {
        tr {
            height: auto;

            td {
                color: var(--ion-color-medium);
                font-size: 14px;
            }

            &:nth-child(even) {
                background: #e0e0e0;

                td {
                    color: #6f6f6f;
                }
            }
        }
    }
}
