.PaleoTop {
    text-align: left;
    background: #fff;
    border-radius: 2pt;
    overflow: hidden;
    margin-bottom: 2px;

    .inner {
        padding: 25px 45px;
    }

    img {
        margin: 0 auto;
        text-align: center;
        display: flex;
        width: 100%;
    }

    .check-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        text-align: left;
        padding: 35px 0;
    
        span {
            display: flex;
            font-size: 24px;
            font-family: "Yanone Kaffeesatz";
            font-weight: 700;
            color: #444;
            align-items: center;
    
            ion-icon {
                margin-right: 10px;
            }
        }
    }
}
