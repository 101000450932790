.alert {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    ion-spinner,
    ion-icon {
        font-size: 60px;
        margin-right: 12px;
    }

    span {
        font-size: 18px;
        font-weight: 600;
    }

    &.small {
        span {
            font-size: 16px;
            font-weight: 400;
        }

        ion-spinner,
        ion-icon {
            font-size: 40px;
            margin-right: 12px;
        }
    }

    &.alert-success {
        color: var(--ion-color-success);
    }

    &.alert-warning {
        color: var(--ion-color-warning);
    }

    &.alert-danger {
        color: var(--ion-color-danger);
    }

    &.alert-medium {
        color: var(--ion-color-medium);
    }

    &.alert-dark {
        color: var(--ion-color-dark);
    }
}
